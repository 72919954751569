import React, { createRef } from 'react';
import PropTypes from 'prop-types';
import Drawer from '@audacious/components/components/Drawer';
import Document, { DocumentHeader, DocumentBody, DocumentFooter } from '@audacious/components/components/Document';
import Button from '@audacious/components/components/Button';
import LinkButton from '@audacious/components/components/LinkButton';
import ButtonGroup from '@audacious/components/components/ButtonGroup';
import Data, { DataSelectProperty, DataTextProperty, DataTextAreaProperty, DataAccess } from '@audacious/components/components/Data';
import { Text, SubHeading } from '@audacious/components/components/Typography';
import FileDrop from '@audacious/components/components/FileDrop';
import { Row, Column } from '@audacious/components/components/Grid';
import RemovableChip from '@audacious/components/components/RemovableChip';
import isNil from 'lodash/isNil';
import get from 'lodash/get';
import map from 'lodash/map';
import mapKeys from 'lodash/mapKeys';
import isEqual from 'lodash/isEqual';
import find from 'lodash/find';
import includes from 'lodash/includes';
import remove from 'lodash/remove';
import PapaParse from 'papaparse';
import { validateDemographic } from '@missing-persons/common/src/common/validate-demographic';
import SearchStatus from '@missing-persons/common/src/common/search-status';
import {
	FIRST_NAME_HEADER,
	MIDDLE_NAME_HEADER,
	LAST_NAME_HEADER,
	DOB_HEADER,
	GENDER_HEADER,
	ADDRESS_1_HEADER,
	ADDRESS_2_HEADER,
	CITY_HEADER,
	STATE_HEADER,
	ZIP_HEADER,
	PHONE_HEADER,
	SSN_HEADER,
	INSURANCE_HEADER,
	DEMOGRAPHIC_HEADERS,
	CUSTOM_HEADERS_MAP,
} from '@missing-persons/common/src/common/csv-headers';

import Constants from '../../../../constants/validation-messages';

import './create-edit-list-drawer.scss';

const {
	NAME_PATTERN,
	MIN_CHARACTERS,
	MAX_CHARACTERS,
	INVALID_VALUE_MSG,
	INVALID_CHARACTERS_MSG,
	MIN_VALUE_MSG,
	MAX_VALUE_MSG,
	CONTAINS_LETTER_PATTERN,
	MUST_CONTAIN_LETTER_MSG,
} = Constants;

function handleDownloadFile() {
	let dataString = '';

	dataString += `${FIRST_NAME_HEADER},`;
	dataString += `${MIDDLE_NAME_HEADER},`;
	dataString += `${LAST_NAME_HEADER},`;
	dataString += `${DOB_HEADER},`;
	dataString += `${GENDER_HEADER},`;
	dataString += `${ADDRESS_1_HEADER},`;
	dataString += `${ADDRESS_2_HEADER},`;
	dataString += `${CITY_HEADER},`;
	dataString += `${STATE_HEADER},`;
	dataString += `${ZIP_HEADER},`;
	dataString += `${PHONE_HEADER},`;
	dataString += `${SSN_HEADER},`;
	dataString += `${INSURANCE_HEADER}`;
	dataString += '%0A';

	dataString += 'Austin,';
	dataString += 'Chris,';
	dataString += 'Anderson,';
	dataString += '1/16/1980,';
	dataString += 'male,';
	dataString += '6520 Sint. Center,';
	dataString += 'Apt 2,';
	dataString += 'Houston,';
	dataString += 'TX,';
	dataString += '19263,';
	dataString += '8607184530,';
	dataString += '501498173,';
	dataString += '1EG4TE5MK72,';

	const link = document.createElement('a');
	link.href = `data:text/csv;charset=UTF-8,${dataString}`;
	link.download = 'missing-persons-template.csv';
	link.click();
}

function rowsMeetQualityThreshold(rows) {
	let validRows = 0;

	const maxRows = 10000;
	const rowsToCheck = rows.length > maxRows ? maxRows : rows.length;

	for (let i = 0; i < rowsToCheck; i += 1) {
		const errors = validateDemographic(rows[i]);

		if (!errors || errors.length === 0) {
			validRows += 1;
		}
	}

	const validRatio = validRows / rowsToCheck;

	return validRatio >= 0.2;
}

class CreateEditListDrawer extends React.Component {
	constructor(props) {
		super(props);

		this.dataRef = createRef();

		this.state = {
			isParsing: false,
			csvImportErrors: null,
			fileName: null,
			csvData: null,
		};

		this.handleOnCancel = this.handleOnCancel.bind(this);
		this.handleExecuteStart = this.handleExecuteStart.bind(this);
		this.handleExecute = this.handleExecute.bind(this);
		this.handleSaveClick = this.handleSaveClick.bind(this);
		this.handleFileSelect = this.handleFileSelect.bind(this);
		this.clearCsvImportState = this.clearCsvImportState.bind(this);
	}

	handleOnCancel() {
		const { onCancel } = this.props;

		this.clearCsvImportState();
		onCancel();
	}

	// eslint-disable-next-line class-methods-use-this
	handleExecuteStart(value, results) {
		if (!isNil(results)) {
			return false;
		}

		const { csvImportErrors } = this.state;

		if (!isNil(csvImportErrors)) {
			return false;
		}

		const { csvData } = this.state;

		// not valid if csv data exists and the event is inactive
		if (csvData) {
			const { events } = this.props;

			const eventId = get(value, 'eventId');
			const event = find(events, { id: eventId });

			if (event && !event.active) {
				return false;
			}

		}

		return true;
	}

	handleExecute(data) {
		const { onSaveComplete } = this.props;
		const { csvData } = this.state;

		onSaveComplete(data, csvData);
		this.clearCsvImportState();
	}

	handleSaveClick() {
		this.dataRef.current.execute();
	}

	handleFileSelect(files, isDisabled) {
		this.clearCsvImportState();

		if (!files || files.length === 0 || isDisabled) {
			return;
		}

		const firstFile = files[0];

		const fileType = get(firstFile, 'type');

		if (fileType !== 'text/csv') {
			this.setState({
				fileName: firstFile.name,
				csvImportErrors: ['Invalid file type'],
			});
			return;
		}

		this.setState({ isParsing: true, fileName: firstFile.name });

		PapaParse.parse(firstFile, {
			complete: (result) => {
				const headers = get(result, 'meta.fields');
				if (!isEqual(headers, DEMOGRAPHIC_HEADERS)) {
					this.setState({
						isParsing: false,
						csvImportErrors: ['Invalid Column Names'],
					});
					return;
				}

				const rows = get(result, 'data', []);

				// Map CSV file headers to the Custom headers
				const updatedRows = map(rows, (row) =>
					mapKeys(row, (val, key) =>
						key in CUSTOM_HEADERS_MAP ? CUSTOM_HEADERS_MAP[key] : key,
					),
				);

				if (!rowsMeetQualityThreshold(updatedRows)) {
					this.setState({
						isParsing: false,
						csvImportErrors: [
							'Unable to upload file. Update CSV file to include minimum demographic information and upload the file again.',
						],
					});
					return;
				}

				this.setState({
					isParsing: false,
					csvData: updatedRows,
				});
			},
			header: true,
			skipEmptyLines: true,
		});
	}

	clearCsvImportState() {
		this.setState({
			csvImportErrors: null,
			fileName: null,
			csvData: null,
		});
	}

	render() {
		const { open, events, headerText, uploadText, initialData } = this.props;
		const { isParsing, csvImportErrors, fileName } = this.state;

		const fileImportMessages = {};

		if (!isNil(csvImportErrors)) {
			fileImportMessages.invalid = csvImportErrors;
		}

		let fileNameRender = null;

		const isPaused = initialData?.status === SearchStatus.PAUSED;

		if (!isNil(fileName)) {
			fileNameRender = (
				<div className="filename-container">
					<RemovableChip
						id="csv-filename-chip"
						color="grey"
						size="xl"
						onClear={this.clearCsvImportState}
					>
						{fileName}
					</RemovableChip>
				</div>
			);
		}

		return (
			<Drawer
				open={open}
				size="sm"
				variant="right"
				id="create-new-list-drawer"
				enableAnimation
				isSaving={isParsing}
			>
				<Document size="sm">
					<DocumentHeader>
						<SubHeading id="create-new-list-heading-text" level="4">
							{headerText}
						</SubHeading>
					</DocumentHeader>
					<DocumentBody>
						<Data
							ref={this.dataRef}
							baseValue={initialData}
							onExecuteStart={this.handleExecuteStart}
							onExecute={this.handleExecute}
							validateOnExecute
							validateOnBlur
							showResultsOnTouch
							showResultsOnExecute
						>
							<Row gutter="16">
								<Column width="12">
									<DataTextProperty
										id="create-list-list-name-field"
										path="title"
										label="List Name*"
										placeholder="Enter search name"
										required={[true, INVALID_VALUE_MSG]}
										minLength={[MIN_CHARACTERS, MIN_VALUE_MSG]}
										maxLength={[MAX_CHARACTERS, MAX_VALUE_MSG]}
										pattern={[NAME_PATTERN, INVALID_CHARACTERS_MSG]}
										attributes={{
											minLetters: {
												enabled: true,
												onValidate: (nameValue) => {
													if (
														nameValue &&
														!CONTAINS_LETTER_PATTERN.test(nameValue)
													) {
														return MUST_CONTAIN_LETTER_MSG;
													}

													return null;
												},
											},
										}}
										generalMessages={['Enter 8-50 characters']}
									/>
								</Column>
							</Row>
							<Row gutter="16">
								<Column width="12">
									<DataSelectProperty
										id="create-list-select-event-field"
										path="eventId"
										label="Event*"
										keyPath="id"
										optionTextPath="name"
										options={events}
										required={[true]}
									/>
								</Column>
							</Row>
							<Row gutter="16">
								<Column width="12">
									<DataTextAreaProperty
										id="create-list-description-field"
										path="description"
										label="Description (Optional)"
										maxLength={400}
									/>
								</Column>
							</Row>
							<Row>
								<Column width="fill" />
								<Column width="content">
									<DataAccess>
										{(dataContext) => {
											const descriptionValue =
												get(
													dataContext,
													'property.value.description',
													'',
												) || '';
											return `${descriptionValue.length}/400`;
										}}
									</DataAccess>
								</Column>
							</Row>
							<div>
								<Text size="lg" weight="semi-bold">
									Upload File
								</Text>
							</div>
							<div>
								<Text size="lg" weight="semi-bold">
									Use the template to create a missing person CSV file
								</Text>
							</div>
							<div>
								<Text size="lg">
									Complete all required fields based on formatting guidelines.
								</Text>
							</div>
							<div>
								<LinkButton
									id="csv-template-download-button"
									color="primary"
									variant="opaque"
									textOnly
									onClick={handleDownloadFile}
								>
									Download a template CSV file
								</LinkButton>
							</div>
							<Row gutter="16">
								<Column width="12">
									<Text size="lg">{uploadText}</Text>
								</Column>
							</Row>
							{fileNameRender}
							<Row gutter="16">
								<Column width="12">
									<DataAccess>
										{(dataContext) => {
											const eventId = get(dataContext, 'property.value.eventId');
											const event = find(events, { id: eventId });
											const eventIsInactive = event && !event.active;
											const importDisabled = isPaused || eventIsInactive;

											const inactiveMessage = 'Can not add file with an inactive event selected.';

											if (eventIsInactive) {
												if (!fileImportMessages?.invalid) {
													fileImportMessages.invalid = [];
												}

												// check to make sure invalid message does not already exist, otherwise it can be added many times
												if (!includes(fileImportMessages.invalid, inactiveMessage)) {
													fileImportMessages.invalid.push(inactiveMessage);
												}
											} else if (fileImportMessages?.invalid) {
												// remove the invalid message when no invalid event is selected
												remove(fileImportMessages.invalid, v => v === inactiveMessage);
											}

											return (
												<FileDrop
													id="missing-persons-csv-import"
													headerText=""
													onFileSelect={files => this.handleFileSelect(files, importDisabled)}
													accept="text/csv"
													messages={fileImportMessages}
													invalid={!isNil(csvImportErrors)}
													disabled={importDisabled}
												/>
											)
										}}
									</DataAccess>
								</Column>
							</Row>
						</Data>
					</DocumentBody>
					<DocumentFooter>
						<ButtonGroup>
							<Button
								id="create-new-list-drawer-save-button"
								type="submit"
								color="primary"
								variant="fill"
								onClick={this.handleSaveClick}
								disabled={isParsing}
							>
								Save
							</Button>
							<Button
								color="primary"
								variant="opaque"
								id="create-new-list-drawer-cancel-button"
								focusOnMount
								onClick={this.handleOnCancel}
								disabled={isParsing}
							>
								Cancel
							</Button>
						</ButtonGroup>
					</DocumentFooter>
				</Document>
			</Drawer>
		);
	}
}

CreateEditListDrawer.defaultProps = {
	initialData: null,
};

CreateEditListDrawer.propTypes = {
	open: PropTypes.bool.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	events: PropTypes.arrayOf(PropTypes.object).isRequired,
	onCancel: PropTypes.func.isRequired,
	onSaveComplete: PropTypes.func.isRequired,
	initialData: PropTypes.shape({
		title: PropTypes.string.isRequired,
		description: PropTypes.string,
		eventId: PropTypes.string,
		status: PropTypes.string,
	}),
	headerText: PropTypes.string.isRequired,
	uploadText: PropTypes.string.isRequired,
};

export default CreateEditListDrawer;
